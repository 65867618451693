import * as React from 'react';
import { InternationalContext } from '@rsc/gatsby-localization-helpers';
import { ReactElement } from 'react';
import { useSsoStaticContentLocalizer } from './src/utils/static-translations';

interface PageWrapperProps {
  element: ReactElement;
  props: { pageContext: { locale: string } };
}

const InternationalContextWrapper = ({ children, locale }: any) => {
  const staticContentLocalizer = useSsoStaticContentLocalizer(locale);

  return (
    <InternationalContext.Provider value={staticContentLocalizer}>
      {children}
    </InternationalContext.Provider>
  );
};

export function PageWrapper({ element, props }: Readonly<PageWrapperProps>) {
  const { locale } = props.pageContext;

  return (
    <InternationalContextWrapper locale={locale}>
      {element}
    </InternationalContextWrapper>
  );
}
