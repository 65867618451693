export const loginPage = {
  key: 'loginPage',
  name: 'Login Page',
  siteIdentifier: 'sso',

  accountSuccessfullyVerifiedMessage:
    'Your account has been successfully verified.',
  passwordSuccessfullyUpdatedMessage:
    'Your password has been successfully updated',
  headTagTitle: 'Login | {brandName}',
  headTagDescription: 'Login Page',

  emailValidationMessage:
    'Please use a valid email address such as user@example.com',
  emailLabel: 'Email',

  passwordRequiredValidationMessage: 'You must enter a password',
  passwordLabel: 'Password',
  passwordMustMatchValidationMessage: 'Passwords must match',

  temporaryPasswordExpiredMessage:
    'Your temporary password has expired. We will send you an email with a new temporary password.',
  somethingWentWrongMessage:
    'Something went wrong. Please contact customer support.',
  invalidMultipassConfigurationMessage:
    'Invalid multipass configuration for brand: {brand}',
  youMustChangePasswordMessage: 'You must change your password.',
  emailOrPasswordIncorrectMessage:
    'Your email address or password is incorrect. Please try again.',
  userNotConfirmedMessage: 'User is not confirmed.',

  emailAddressLabel: 'Email Address',
  enterNewPasswordLabel: 'Enter a new password',
  confirmPasswordLabel: 'Confirm password',
  signInButton: 'Sign In',
  forgotYourPasswordLink: 'Forgot your password?',
};
