export const updatePasswordPage = {
  key: 'updatePasswordPage',
  name: 'Update Password Page',
  siteIdentifier: 'sso',

  headTagTitle: 'Update Password | {brandName}',
  headTagDescription: 'Update Password Page',

  enterCurrentPasswordValidationMessage: 'Please enter your current password',
  passwordLabel: 'Password',
  choosePasswordWithAtLeastEightCharsValidationMessage:
    'Please choose a password with at least 8 characters',

  passwordsMustMatchValidationMessage: 'Passwords must match',
  confirmPasswordLabel: 'Confirm Password',
  incorrectPasswordEnteredMessage:
    'The current password you entered is incorrect. Please try again.',
  loginBeforeChangingPasswordMessage:
    'You are currently not logged in. Please login first and then try changing your password.',
  accountPasswordUpdatedSuccessfullyMessage:
    'Account Password updated successfully',

  currentPasswordLabel: 'Current Password',
  newPasswordLabel: 'New Password',
  passwordMustContainAtLeastEightCharsHint:
    'Password must contain at least 8 characters',
  confirmNewPasswordLabel: 'Confirm New Password',
  updatePasswordButton: 'Update Password',
};
