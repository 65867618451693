import { LocalizationDataExternal } from '@rsc/sanity-static-localization-provider-library';
import {
  useInternationalContext,
  useInternationalHelper,
} from '@rsc/gatsby-localization-helpers';
import { loginPage } from './loginPage';
import { registerPage } from './registerPage';
import { resetPasswordPage } from './resetPasswordPage';
import { updatePasswordPage } from './updatePasswordPage';
import { layoutComponent } from './layoutComponent';

export const ssoLocalizationStructure = {
  loginPage,
  registerPage,
  resetPasswordPage,
  updatePasswordPage,
  layoutComponent,
};

// Use this hook to initialize InternationalContext for SSO site
export const useSsoStaticContentLocalizer = (locale: string) => {
  let translationCache: LocalizationDataExternal;

  try {
    translationCache =
      // eslint-disable-next-line global-require
      require('../../../localization.json') as LocalizationDataExternal;
  } catch {
    translationCache = { categories: [] }; // Fallback for missing file
  }

  return useInternationalHelper(
    locale,
    translationCache,
    ssoLocalizationStructure
  );
};

export const useSsoInternationalContext = () =>
  useInternationalContext(ssoLocalizationStructure);
