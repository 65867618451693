export const registerPage = {
  key: 'registerPage',
  name: 'Register Page',
  siteIdentifier: 'sso',

  headTagTitle: 'Register | {brandName}',
  headTagDescription: 'Register Page',

  firstNameRequiredValidationMessage: "The 'First Name' field cannot be blank",
  firstNameLabel: 'First Name',
  lastNameRequiredValidationMessage: "The 'Last Name' field cannot be blank",
  lastNameLabel: 'Last Name',
  emailValidationMessage:
    'Please use a valid email address such as user@example.com',
  emailLabel: 'Email',
  passwordValidationMessage:
    'Please choose a password with at least 8 characters',
  passwordValidationTipLabel: 'Password must contain at least 8 characters',
  passwordLabel: 'Password',
  passwordMustMatchValidationMessage: 'Passwords must match',
  confirmPasswordLabel: 'Confirm Password',

  yourEmailAddressText: 'your email address',
  almostThereText: 'Almost there!',

  postRegistrationInstructionText: `We sent you a link to verify your email address at {userEmail}.
            Please open this link to complete your registration. If you
            don't receive this email, please check your junk mail folder,
            or {contactUsLink} for further assistance.`,

  contactUsText: 'contact us',
  continueShoppingText: 'Continue Shopping',

  emailAddressLabel: 'Email Address',
  choosePasswordLabel: 'Choose Password',

  proveYouAreNotRobotMessage:
    'Please check the box to prove you are not a robot!',

  createAccountButton: 'Create Account',

  usernameExistsExceptionMessage:
    'An account with the given email already exists',
};
