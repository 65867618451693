export const resetPasswordPage = {
  key: 'resetPasswordPage',
  name: 'Reset Password Page',
  siteIdentifier: 'sso',

  headTagTitle: 'Reset Password | {brandName}',
  headTagDescription: 'Reset Password Page',

  emailValidationMessage:
    'Please use a valid email address such as user@example.com',
  emailLabel: 'Email',
  newPasswordLabel: 'New Password',
  passwordsMustMatchValidationMessage: 'Passwords must match',
  reEnterPasswordLabel: 'Re-enter password',
  mustEnterValidCodeValidationMessage: 'You must enter a valid code',
  verificationCodeLabel: 'Verification Code',
  newTempPasswordInstruction: `If the entered email address is associated with this
                      store, you will receive a temporary password via email.
                      If you don't receive this email, please check your
                      junk mail folder or {contactUsLink} for further assistance.`,

  contactUsText: 'contact us',

  verificationCodeInstruction: `If the entered email address is associated with this
                      store, you will receive a verification code via email.
                      If you don't receive this email, please check your
                      junk mail folder or {contactUsLink} for further assistance.`,

  passwordSuccessfullyUpdatedMessage:
    'Your password has been successfully updated.',

  requestPasswordResetLinkInstruction: `Fill in your email below to request a link to reset your password
            and reactivate your {brandDisplayName} account.`,

  requestNewPasswordInstruction: `Fill in your email below to request a new password. A verification
            code will be sent to the address below to verify your email
            address`,

  emailAddressLabel: 'Email Address',
  resetPasswordButton: 'Reset Password',
  passwordLengthValidationMessage:
    'Password must contain at least 8 characters',

  confirmPasswordLabel: 'Confirm Password',
  setNewPasswordButton: 'Set New Password',
  logInLink: 'Log in',

  codeMismatchErrorMessage:
    'Invalid verification code provided, please try again.',
};
