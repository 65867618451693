// eslint-disable-next-line import/prefer-default-export
import { PageWrapper } from './PageWrapper';

export const onClientEntry = () => {
  // Ensure this runs in the browser
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    let brand = urlParams.get('brand') || 'default'; // Provide a fallback brand

    if (brand === 'invisiblefence_canada') {
      brand = 'invisiblefence';
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `/styles/${brand}.css`;
    document.head.appendChild(link);
  }
};

export const wrapPageElement = PageWrapper;
